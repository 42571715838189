import React from "react"

/**
 * Change the content to add your own bio
 */

export default function Bio() {
  return (
    <>
      👋 I'm Paul Kiddie, a software engineer working in London.
      I'm currently working as a Principal Engineer at trainline.
    </>
  )
}
